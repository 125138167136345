<template>
  <div class="_nav-bar__navbarContainer__1nCgO">
    <div class="_nav-bar__head__1uFFd">
      <div id="loginapp" class="_nav-bar__headerbox__15BAD">
        <div class="_nav-bar__leftbox__1oHQv">
          <span class="_nav-bar__line1__3KjiX">GMT+8</span
          ><span class="_nav-bar__margin10__2udrl">{{ nowTime }} </span>
          <span style="width: 25px; padding-left: 10px; padding-top: 5px"
            ><img src="/static/image/lan_ch.png"
          /></span>
          <span style="width: 25px; padding-left: 15px; padding-top: 5px"
            ><img src="/static/image/lan_tw.png"
          /></span>
        </div>
        <div class="_nav-bar__rightbox__3GO2_" v-if="!$store.state.token">
          <div class="_nav-bar__userInfo__3rF_n">
            <div class="_nav-bar__input__17hJY">
              <i></i>
              <input
                style="font-size: 12px"
                type="text"
                v-model="loginInfo.name"
                placeholder="账号"
                id="hd_account_tc"
                value=""
                class="_nav-bar__inputbox__5XjvL _nav-bar__username__3ePGp"
              />
            </div>
            <div class="_nav-bar__pwdBox__1fARs _nav-bar__input__17hJY">
              <i></i>
              <input
                style="font-size: 12px"
                type="password"
                v-model="loginInfo.password"
                placeholder="密码"
                id="hd_passwd_tc"
                maxlength="32"
                value=""
                class="_nav-bar__inputbox__5XjvL undefined"
              />
            </div>
            <!-- 验证码 -->
            <!-- <div class="_nav-bar__input__17hJY">
              <i></i>
              <input type="text" v-model="loginInfo.code" placeholder="验证码" style="width: 82px" class="_nav-bar__inputbox__5XjvL _nav-bar__username__3ePGp" />
            </div> -->
            <img
              :src="`/static/image/yzm/${imgLis[index]}.png`"
              style="height: 30px; cursor: pointer; margin-right: 20px"
              @click="changIndex"
              alt=""
            />

            <div
              @click="login"
              class="_nav-bar__loginbtn__2chaT _nav-bar__changeBgColor__Q_xKS"
            >
              登录
            </div>
            <!-- <div class="_nav-bar__registerbtn__3TgmB _nav-bar__changeBgColor__Q_xKS"><a @click="$parent.goNav('/register')" class="shiwan1">注册</a></div> -->
          </div>
        </div>
        <div class="_nav-bar__rightbox__3GO2_" v-else>
          <div class="_nav-bar__userInfo__3rF_n">
            <span class="_nav-bar__username__3ePGp" style="cursor: pointer"
              ><b>欢迎您，</b
              ><a @click="$parent.goNav('/center')">{{
                $store.state.userInfo.username
              }}</a>
              <img
                @click="$parent.goNav('/vip')"
                class="_nav-bar__vip_icon__fmRGZ"
                style="width: 43px !important; height: 15px !important"
                :src="baseURL + $store.state.userInfo.vipname"
                alt=""
              />
              <!-- <a @click="$parent.goNav('/vip')">{{ $store.state.userInfo.current_vip }}</a> -->
            </span>
            <span class="_nav-bar__line1__3KjiX">|</span>
            <span class="_nav-bar__money__3uMi5" style="cursor: pointer">
              <!-- <img src="/static/image/icon-yincang@2x-d85ec8b5023c6458133d9671400dec17.png"  data-type="money_switch" alt="" class="_nav-bar__money_switch__3ziSa" /> -->
              总资产：
              <em class="than" @click="$parent.goNav('/transfer')"
                >{{ $store.state.userInfo.balance }} 元</em
              ><img
                src="/static/image/shuaxin@2x-5b83e4c9205a4241b51f544a75f26bab.png"
                @click="$parent.getUserInfoShowLoding"
                alt=""
                data-type="refresh"
                class="_nav-bar__refresh_mnoney__3csL-"
            /></span>
            <span class="_nav-bar__actions__3Z8-N">
              <!-- <a @click="$parent.goNav('/recharge')">存款</a>
              <a @click="$parent.goNav('/withdraw')">取款</a> -->
              <a
                @click="$parent.goNav('/transfer')"
                class="_nav-bar__notMagrinR__ZcWN-"
                >转账</a
              ></span
            >
            <span class="_nav-bar__line1__3KjiX">|</span>
            <a @click="$parent.outLogin"
              ><span
                title="退出登录"
                class="_nav-bar__logout__3yoqE"
                style="cursor: pointer; margin-right: 0px; color: #fff"
              ></span
            ></a>
          </div>
        </div>
      </div>
    </div>

    <div class="_nav-bar__contentBg__2fJ6P">
      <div class="_nav-bar__navbarContent__33YJf">
        <img
          :src="$store.state.appInfo.site_logo"
          onerror="this.src='/static/image/logo.svg'"
          class="_nav-bar__navbarimg__22XW_"
        />
        <nav>
          <ul class="_nav-bar__nav_ul__2w1iF">
            <li class="_nav-bar__nav_item__11Zax">
              <div
                :class="
                  path == '/'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <span><a @click="$parent.goNav('/')">首页</a></span
                ><i></i>
              </div>
            </li>

            <li class="_nav-bar__nav_item__11Zax">
              <div
                :class="
                  path == '/realbet'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <span><a @click="$parent.goNav('/realbet')">真人娱乐</a></span
                ><i></i>
              </div>
              <div
                class="navStyles__navbox__2Mgk6 navStyles__zr__1rJUS"
                style="height: 0px"
              >
                <ul class="navStyles__navGroup__2yL5t" style="width: unset">
                  <li
                    class="navStyles__navItem__w3dcG navStyles__topTitle__21ANq"
                    v-for="(item, index) in realbetList"
                    :key="index"
                    v-if="index < 4"
                    @click="
                      $parent.openGamePage(
                        item.platform_name,
                        item.game_code,
                        ''
                      )
                    "
                  >
                    <img
                      :src="realbetImg[index]['img']"
                      width="270px"
                      class="navStyles__model__2jfhz"
                      style=""
                    /><span class="navStyles__itemTop__2N2zG">
                      <h2 class="navStyles__itemTitle__4fyMp">
                        {{ item.name }}
                      </h2>
                      <p class="navStyles__itemText__P2uUa">
                        {{ realbetImg[index]["title"] }}
                      </p>
                    </span>
                  </li>

                  <span
                    class="navStyles__boon__1NURp"
                    @click="$parent.goNav('/realbet')"
                    ><span class="navStyles__boonTitle__30StN">真人娱乐</span
                    ><span class="navStyles__boonEnTitle__q37B8"
                      >LIVE CASINO</span
                    ><img
                      src="/static/image/return.22f59de327e8df671b50b1f4e8090c63.png"
                      width="91px"
                      style=""
                    /><span class="navStyles__boonSubTitle__3CvIH"
                      >天天最高返水</span
                    ></span
                  >
                </ul>
              </div>
            </li>

            <li class="_nav-bar__nav_item__11Zax">
              <div
                :class="
                  path == '/sport'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <span><a @click="$parent.goNav('/sport')">体育赛事</a></span
                ><i></i>
              </div>
              <div
                class="navStyles__navbox__2Mgk6 navStyles__ty__X5SzN"
                style="height: 0px"
              >
                <ul class="navStyles__navGroup__2yL5t" style="width: unset">
                  <li
                    class="navStyles__navItem__w3dcG"
                    v-for="(item, index) in sportList"
                    :key="index"
                    v-if="index < 3"
                    @click="
                      $parent.openGamePage(
                        item.platform_name,
                        item.game_code,
                        ''
                      )
                    "
                  >
                    <img
                      :src="sportImg[index]['img']"
                      width="238px"
                      class="navStyles__model__2jfhz"
                      style=""
                    /><span class="navStyles__itemRight__IIsdu">
                      <h2 class="navStyles__itemTitle__4fyMp">
                        {{ item.name }}
                      </h2>
                      <p class="navStyles__itemText__P2uUa">
                        {{ sportImg[index]["title"] }}
                      </p>
                      <img
                        src="/static/image/btn-enter.62374a382990732bc66da6739112a0b8.png"
                        width="110px"
                        class="navStyles__itemBtn__3ANXo"
                        style=""
                    /></span>
                  </li>
                  <span
                    class="navStyles__boon__1NURp"
                    @click="$parent.goNav('/sport')"
                    ><span class="navStyles__boonTitle__30StN">体育赛事</span
                    ><span class="navStyles__boonEnTitle__q37B8"
                      >SPORTS EVENTS</span
                    ><img
                      src="/static/image/return.ede09f746df6fc6671d4252a2c64a491.png"
                      width="94px"
                      style=""
                    /><span class="navStyles__boonSubTitle__3CvIH"
                      >天天最高返水</span
                    ></span
                  >
                </ul>
              </div>
            </li>
            <li class="_nav-bar__nav_item__11Zax">
              <div
                :class="
                  path == '/gaming'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <span><a @click="$parent.goNav('/gaming')">电子竞技</a></span
                ><i></i>
              </div>
              <div
                class="navStyles__navbox__2Mgk6 navStyles__dj__qH0IU"
                style="height: 0px"
              >
                <ul class="navStyles__navGroup__2yL5t" style="width: unset">
                  <li
                    class="navStyles__navItem__w3dcG navStyles__topTitle__21ANq"
                    v-for="(item, index) in gamingList"
                    :key="index"
                    v-if="index < 4"
                    @click="
                      $parent.openGamePage(
                        item.platform_name,
                        item.game_code,
                        ''
                      )
                    "
                  >
                    <img
                      :src="gamingImg[index]['img']"
                      width="280px"
                      class="navStyles__model__2jfhz"
                      style=""
                    /><span class="navStyles__itemTop__2N2zG">
                      <h2 class="navStyles__itemTitle__4fyMp">
                        {{ item.name }}
                      </h2>
                      <p class="navStyles__itemText__P2uUa">
                        {{ gamingImg[index]["title"] }}
                      </p>
                    </span>
                  </li>
                  <span
                    class="navStyles__boon__1NURp"
                    @click="$parent.goNav('/gaming')"
                    ><span class="navStyles__boonTitle__30StN">电子竞技</span
                    ><span class="navStyles__boonEnTitle__q37B8">E-SPORTS</span
                    ><img
                      src="/static/image/return.1b7a0d3387bf0358523d91f47d1d2c25.png"
                      width="94px"
                      style=""
                    /><span class="navStyles__boonSubTitle__3CvIH"
                      >天天最高返水</span
                    ></span
                  >
                </ul>
              </div>
            </li>

            <li class="_nav-bar__nav_item__11Zax">
              <div
                :class="
                  path == '/joker'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <span><a @click="$parent.goNav('/joker')">棋牌游戏</a></span
                ><i></i>
              </div>
              <div
                class="navStyles__navbox__2Mgk6 navStyles__qp__3ByyR"
                style="height: 0px"
              >
                <ul class="navStyles__navGroup__2yL5t" style="width: unset">
                  <li
                    class="navStyles__navItem__w3dcG"
                    v-for="(item, index) in jokerList"
                    :key="index"
                    v-if="index < 4"
                    @click="
                      $parent.openGamePage(
                        item.platform_name,
                        item.game_code,
                        ''
                      )
                    "
                  >
                    <img
                      :src="jokerImg[index]['img']"
                      width="192px"
                      class="navStyles__model__2jfhz"
                      style=""
                    /><span class="navStyles__itemRight__IIsdu">
                      <h2 class="navStyles__itemTitle__4fyMp">
                        {{ item.name }}
                      </h2>
                      <p class="navStyles__itemText__P2uUa">
                        {{ jokerImg[index]["title"] }}
                      </p>
                    </span>
                  </li>

                  <span
                    class="navStyles__boon__1NURp"
                    @click="$parent.goNav('/joker')"
                    ><span class="navStyles__boonTitle__30StN">棋牌游戏</span
                    ><span class="navStyles__boonEnTitle__q37B8"
                      >CHESS GAME</span
                    ><img
                      src="/static/image/return.8dc572ee278800cc5d0cb1ac2362c6e4.png"
                      width="99px"
                      style=""
                    /><span class="navStyles__boonSubTitle__3CvIH"
                      >棋牌连赢，最高</span
                    ></span
                  >
                </ul>
              </div>
            </li>

            <li class="_nav-bar__nav_item__11Zax">
              <div
                :class="
                  path == '/concise'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <span><a @click="$parent.goNav('/concise')">电子游艺</a></span
                ><i></i>
              </div>
              <div
                class="navStyles__navbox__2Mgk6 navStyles__dz__10OwB"
                style="height: 0px"
              >
                <ul class="navStyles__navGroup__2yL5t" style="width: unset">
                  <li
                    class="navStyles__navItem__w3dcG navStyles__topTitle__21ANq"
                    v-for="(item, index) in conciseList"
                    :key="index"
                    v-if="index < 5"
                    @click="
                      $parent.openGamePage(
                        item.platform_name,
                        item.game_code,
                        ''
                      )
                    "
                  >
                    <img
                      :src="conciseImg[index]['img']"
                      width="224px"
                      class="navStyles__model__2jfhz"
                      style=""
                    /><span class="navStyles__itemTop__2N2zG">
                      <h2 class="navStyles__itemTitle__4fyMp">
                        {{ item.name }}
                      </h2>
                      <p class="navStyles__itemText__P2uUa">
                        {{ conciseImg[index]["title"] }}
                      </p>
                    </span>
                  </li>

                  <span
                    class="navStyles__boon__1NURp"
                    @click="$parent.goNav('/concise')"
                    ><span class="navStyles__boonTitle__30StN">电子游艺</span
                    ><span class="navStyles__boonEnTitle__q37B8">SLOT GAME</span
                    ><img
                      src="/static/image/return.425ed49de821efbc6b07cd7cc99223c7.png"
                      width="98px"
                      style=""
                    /><span class="navStyles__boonSubTitle__3CvIH"
                      >天天最高返水</span
                    ></span
                  >
                </ul>
              </div>
            </li>

            <li class="_nav-bar__nav_item__11Zax">
              <div
                :class="
                  path == '/lottery'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <span><a @click="$parent.goNav('/lottery')">彩票投注</a></span
                ><i></i>
              </div>
              <div
                class="navStyles__navbox__2Mgk6 navStyles__cp__2WipM"
                style="height: 0px"
              >
                <ul class="navStyles__navGroup__2yL5t" style="width: unset">
                  <li
                    class="navStyles__navItem__w3dcG"
                    v-for="(item, index) in lotteryList"
                    :key="index"
                    v-if="index < 3"
                    @click="
                      $parent.openGamePage(
                        item.platform_name,
                        item.game_code,
                        ''
                      )
                    "
                  >
                    <img
                      :src="lotteryImg[index]['img']"
                      width="280px"
                      class="navStyles__model__2jfhz"
                      style=""
                    /><span class="navStyles__itemRight__IIsdu">
                      <h2 class="navStyles__itemTitle__4fyMp">
                        {{ item.name }}
                      </h2>
                      <p class="navStyles__itemText__P2uUa">
                        {{ lotteryImg[index]["title"] }}
                      </p>
                      <a
                        href="/member/game?plat_name=vrbet&amp;game_type=3"
                        target="_blank"
                        ><img
                          src="/static/image/btn-enter.62374a382990732bc66da6739112a0b8.png"
                          width="110px"
                          class="navStyles__itemBtn__3ANXo"
                          style="" /></a
                    ></span>
                  </li>
                  <span
                    class="navStyles__boon__1NURp"
                    @click="$parent.goNav('/lottery')"
                    ><span class="navStyles__boonTitle__30StN">彩票游戏</span
                    ><span class="navStyles__boonEnTitle__q37B8"
                      >LOTTERY GAME</span
                    ><img
                      src="/static/image/return.3e15398124241be163586b022d90c06e.png"
                      width="94px"
                      style=""
                    /><span class="navStyles__boonSubTitle__3CvIH"
                      >首存即送最高可享</span
                    ></span
                  >
                </ul>
              </div>
            </li>
          </ul>
        </nav>
        <nav>
          <ul class="_nav-bar__nav_ul__2w1iF _nav-bar__nav_ul2__2jAev">
            <li class="_nav-bar__nav_item__11Zax" @click="$parent.openKefu">
              <div
                :class="
                  path == '/kefu'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <!-- <img
                  src="/static/image/kf.db2638cc7222c080d75627fd6eb158db.svg"
                  width="32px"
                  height="32px"
                /><span style="font-size: 14px"><a>客服</a></span> -->
              </div>
            </li>
            <li class="_nav-bar__nav_item__11Zax">
              <div
                @click="$parent.goNav('/activity')"
                :class="
                  path == '/activity' || path == '/activityInfo'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <img
                  src="/static/image/discount.764db12c939e1b3d0d0744f61383d92b.svg"
                  width="32px"
                  height="32px"
                /><span style="font-size: 14px"><a>优惠</a></span>
              </div>
              <div
                class="navStyles__navbox__2Mgk6 navStyles__yh__3oKDT"
                style="height: 0px"
              >
                <ul class="navStyles__navGroup__2yL5t" style="width: unset">
                  <li
                    class="navStyles__navItem__w3dcG"
                    v-for="(item, index) in activitylistList"
                    :key="index"
                    v-if="index < 4"
                    @click="$parent.goNav(`/activityInfo?id=${item.id}`)"
                  >
                    <img
                      :src="activityImg[index]['img']"
                      width="200px"
                      class="navStyles__model__2jfhz"
                    /><span class="navStyles__itemRight__IIsdu">
                      <h2 class="navStyles__itemTitle__4fyMp">
                        {{ item.title }}
                      </h2>
                      <img
                        src="/static/image/btn-details.399a3c5349c1bb71be5bf267cfbd45d7.png"
                        width="100px"
                        class="navStyles__itemBtn__3ANXo"
                    /></span>
                  </li>
                </ul>
              </div>
            </li>
            <li
              class="_nav-bar__nav_item__11Zax"
              @click="$parent.getAgentLoginUrl"
            >
              <div class="_nav-bar__nav_bar_item_inner__1Jx72">
                <img
                  src="/static/image/sponsor.80ac76b8f6b7c0d2b404991d75685641.svg"
                  width="32px"
                  height="32px"
                /><span style="font-size: 14px"><a>代理</a></span>
              </div>
            </li>

            <li
              class="_nav-bar__nav_item__11Zax"
              @click="$parent.goNav('/app')"
            >
              <div
                :class="
                  path == '/app'
                    ? '_nav-bar__nav_bar_item_inner__1Jx72 _nav-bar__isActive__2x4bL'
                    : '_nav-bar__nav_bar_item_inner__1Jx72 '
                "
              >
                <img
                  src="/static/image/app.725c1a95f62c74d317c401d69944bc68.svg"
                  width="32px"
                  height="32px"
                /><span style="font-size: 14px"><a>APP</a></span>
              </div>
            </li>
          </ul>
        </nav>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: "Header",
  data() {
    return {
      baseURL: "",
      bfNum: 0,
      vipLis: [],
      loginInfo: {},
      nowTime: "",
      nowTimeDao: null,
      realbetList: [],
      jokerList: [],
      gamingList: [],
      sportList: [],
      lotteryList: [],
      conciseList: [],
      realbetImg: [
        {
          title: "身临其境 美女如云",
          img: "/static/image/model-ob.f7d9288eb03c74e2719a47c8522617ae.png",
        },
        {
          title: "美艳荷官 现场互动",
          img: "/static/image/model-bg.ec17e9248b1abbeaef8de1b0884288a0.png",
        },
        {
          title: "视觉盛宴 革命体验",
          img: "/static/image/model-ag.c294fe9c7dedeec529f992f4130b82d5.png",
        },
        {
          title: "现场直播 美女互动",
          img: "/static/image/model-ebet.e68c32250e149edefcedccc56347164d.png",
        },
      ],
      sportImg: [
        {
          title: "多元化的万场投注赛事",
          img: "/static/image/model-ob.4a6215c4c47ed9bddc336c89a60da03b.png",
        },
        {
          title: "全亚洲口碑最好的品牌",
          img: "/static/image/model-xj.cf8af7740c9e164bdbf5bf4954a1ce6c.png",
        },
        {
          title: "专业体育赛事投注",
          img: "/static/image/model-im.fd9d7c98531bbf699c6127700af9cd7d.png",
        },
      ],
      gamingImg: [
        {
          title: "主流赛事 专业玩家",
          img: "/static/image/model-ob.ebb35e6306efb15a10697acc8c033313.png",
        },
        {
          title: "专业电竞平台 值得信赖",
          img: "/static/image/model-lh.e6b48f117643602b3e950ff430f4fa1c.png",
        },
        {
          title: "畅玩电竞 不二之选",
          img: "/static/image/model-fy.13a024900754d2a8d121aeded914b7b8.png",
        },
        {
          title: "精彩赛事 主流玩法",
          img: "/static/image/model-ob.7f26dc12242baa4ed30560f72a98f097.png",
        },
      ],
      jokerImg: [
        {
          title: "真实万人 同台竞技",
          img: "/static/image/model-ob.adabd64f040dcc73c142c3455bf4e298.png",
        },
        {
          title: "热门游戏 应有尽有",
          img: "/static/image/model-im.299443815640aa1e05d29007a0902cb9.png",
        },
        {
          title: "好友相约 竞技娱乐",
          img: "/static/image/model-gd.d31b2632a15154a3280a99c5b14afa7e.png",
        },
        {
          title: "棋牌娱乐 惊喜不断",
          img: "/static/image/model-by.22947db6915caa29e85c38a94532eb15.png",
        },
      ],
      lotteryImg: [
        {
          title: "旗舰彩种 应有尽有",
          img: "/static/image/model-ob.a9c43641662cc922653821e41b271398.png",
        },
        {
          title: "经典彩种 极易操作",
          img: "/static/image/model-sg.42fec46df9aa69fe79e44bcaae2c5775.png",
        },
        {
          title: "业界首创区块链彩票",
          img: "/static/image/model-tcg.f36c7f872d1d19309f182fb89a82f6b4.png",
        },
      ],
      activityImg: [
        {
          title: "真实万人 同台竞技",
          img: "/static/image/model-sc.6648a22dcebb241306d0bafea23e7a11.png",
        },
        {
          title: "热门游戏 应有尽有",
          img: "/static/image/model-tz.38e4f21978b8c3a75f337fbe1e0cd59f.png",
        },
        {
          title: "好友相约 竞技娱乐",
          img: "/static/image/model-ob.59409dd00c09a2fd426cc24c42cabf11.png",
        },
        {
          title: "棋牌娱乐 惊喜不断",
          img: "/static/image/model-yh.d831f71c5e790f33a17512656db97d03.png",
        },
      ],
      conciseImg: [
        {
          title: "经典游戏 巨额大奖",
          img: "/static/image/concises/dz-1.3e3d53d73f4b94714f55db58891ecb6a.png",
        },
        {
          title: "享受视觉盛宴",
          img: "/static/image/concises/dz-2.b89cf0cffb1da2ca26aead2dad4ca33a.png",
        },
        {
          title: "推陈出新 趣妙横生",
          img: "/static/image/concises/dz-3.cb757b71e983a8f35f0e9231baafa3b0.png",
        },
        {
          title: "经典玩法 高额奖金随心拿",
          img: "/static/image/concises/dz-4.b989cf61999e6d75c33a2deb973a179d.png",
        },
        {
          title: "这片区域归你了",
          img: "/static/image/concises/dz-5.d772e90ba70a2e1405d6205613f5c074.png",
        },
      ],
      activitylistList: [],
      imgLis: [
        "2PYL",
        "6AQ5",
        "8PHD",
        "21I7",
        "69HM",
        "ACWA",
        "DUZ7",
        "IY98",
        "K647",
        "M52T",
        "NY52",
        "NZFA",
        "SN76",
        "SP4D",
        "VAEO",
        "YFQM",
        "ZZU5",
        "7GQT",
        "LFW3",
        "NU2T",
        "UAE3",
      ],

      index: 0,
      is_Initial_password: "",
    };
  },
  created() {
    let that = this;
    that.changIndex();

    that.baseURL = sessionStorage.getItem("baseURL") || "";
    console.log(that.baseURL);
    that.getGameList();
    that.activitylist();

    that.openTime();
  },
  methods: {
    changIndex() {
      this.index = parseInt(20 * Math.random());
    },
    activitylist() {
      let that = this;
      that.$parent.showLoading();
      that.$apiFun.post("/api/activitylist", {}).then((res) => {
        if (res.code === 200) {
          that.activitylistList = res.data.data;
        }
        that.$parent.hideLoading();
      });
    },
    getGameList() {
      let that = this;

      that.$apiFun.get("/api/game/list", { category: "" }).then((res) => {
        if (res.code == 200) {
          let list = res.data;
          // that[name] = res.data;
          list.forEach((el) => {
            if (el.category_id == "realbet" && el.app_state == 1) {
              that.realbetList.push(el);
            }
            if (el.category_id == "joker" && el.app_state == 1) {
              that.jokerList.push(el);
            }
            if (el.category_id == "gaming" && el.app_state == 1) {
              that.gamingList.push(el);
            }
            if (el.category_id == "sport" && el.app_state == 1) {
              that.sportList.push(el);
            }
            if (el.category_id == "lottery" && el.app_state == 1) {
              that.lotteryList.push(el);
            }
            if (el.category_id == "concise" && el.app_state == 1) {
              that.conciseList.push(el);
            }

            localStorage.setItem(
              "realbetList",
              JSON.stringify(that.realbetList)
            );
            localStorage.setItem("jokerList", JSON.stringify(that.jokerList));
            localStorage.setItem("gamingList", JSON.stringify(that.gamingList));
            localStorage.setItem("sportList", JSON.stringify(that.sportList));
            localStorage.setItem(
              "lotteryList",
              JSON.stringify(that.lotteryList)
            );
            localStorage.setItem(
              "conciseList",
              JSON.stringify(that.conciseList)
            );
            that.$store.commit("changGameList");
          });
        }
      });
    },
    openTime() {
      let that = this;
      that.nowTimeDao = setInterval(() => {
        that.getFormatDate();
      }, 1000);
    },
    getFormatDate() {
      let that = this;
      var date = new Date();
      var month = date.getMonth() + 1;
      var strDate = date.getDate();
      var hours = date.getHours();
      var minutes = date.getMinutes();
      var seconds = date.getSeconds();
      if (month >= 1 && month <= 9) {
        month = "0" + month;
      }
      if (strDate >= 0 && strDate <= 9) {
        strDate = "0" + strDate;
      }
      if (hours >= 0 && hours <= 9) {
        hours = "0" + hours;
      }
      if (minutes >= 0 && minutes <= 9) {
        minutes = "0" + minutes;
      }
      if (seconds >= 0 && seconds <= 9) {
        seconds = "0" + seconds;
      }
      that.nowTime =
        date.getFullYear() +
        "-" +
        month +
        "-" +
        strDate +
        " " +
        date.getHours() +
        ":" +
        minutes +
        ":" +
        seconds;
    },
    login() {
      let that = this;
      let info = that.loginInfo;
      if (!info.name || !info.password) {
        that.$parent.showTost(0, "请输入您的账号和密码！");
        return;
      }
      // let code = that.loginInfo.code;
      // if (!code) {
      //   that.$parent.showTost(0, "请输入验证码！");
      //   return;
      // }
      // if (code.toUpperCase() != that.imgLis[that.index]) {
      //   that.$parent.showTost(0, "验证码错误！");
      //   that.loginInfo.code = null;
      //   that.changIndex();
      //   return;
      // }
      that.$parent.showLoading();
      that.$apiFun.login(info).then((res) => {
        if (res.code !== 200) {
          that.$parent.showTost(0, res.message);
          that.loginInfo.code = null;
          that.changIndex();
          that.$parent.hideLoading();
        }
        if (res.code === 200) {
          that.is_Initial_password = res.data.is_Initial_password;
          sessionStorage.setItem("token", res.data.api_token);
          that.$store.commit("changToken");
          that.getUserInfo();
          that.$parent.openDaoTime();
        }
      });
    },
    getUserInfo() {
      let that = this;
      that.$apiFun.post("/api/user", {}).then((res) => {
        console.log(res);
        if (res.code !== 200) {
          that.$parent.showTost(0, res.message);
        }
        if (res.code === 200) {
          localStorage.setItem("userInfo", JSON.stringify(res.data));
          that.$store.commit("changUserInfo");
          if (that.is_Initial_password == 1) {
            that.$parent.showTost(0, "请修改您的登录密码！");
            that.$router.push({ path: "/center" });
          } else {
            that.$router.push({ path: "/" });
          }
        }
        that.$parent.hideLoading();
      });
    },
    outLogin() {
      let that = this;
      that.$parent.outLogin();
    },
    changPath(path) {
      let _this = null;
      //首頁
      if (path == "/") {
        //  let _this = $('.wallet_tranfers').children().eq(e); //当前点击元素
        _this = $("#meyNav").children().eq(0);
      }
      //棋牌游戏
      if (path == "/joker") {
        _this = $("#meyNav").children().eq(1);
      }
      //电子游艺
      if (path == "/concise") {
        _this = $("#meyNav").children().eq(2);
      }
      //真人视讯
      if (path == "/realbet") {
        _this = $("#meyNav").children().eq(3);
      }
      //电竞游戏
      if (path == "/gaming") {
        _this = $("#meyNav").children().eq(5);
      }
      //彩票投注
      if (path == "/lottery") {
        _this = $("#meyNav").children().eq(6);
      }
      //体育赛事
      if (path == "/sport") {
        _this = $("#meyNav").children().eq(7);
      }

      if (_this != null) {
        _this.siblings().removeClass("selected");
        _this.addClass("selected");
      } else {
        $("#meyNav").children().eq(0).siblings().removeClass("selected");
        $("#meyNav").children().eq(1).siblings().removeClass("selected");
      }
    },
  },
  updated() {
    let that = this;
    that.changPath(that.$route.path);
  },
  mounted() {},
  beforeDestroy() {
    let that = this;
    if (that.nowTimeDao) {
      clearInterval(that.nowTimeDao);
    }
    that.nowTimeDao = null;
  },
  watch: {
    //监听路由地址的改变
    $route: {
      immediate: true,
      handler() {
        let that = this;
        // that.changPath(that.$route.path);
        that.path = that.$route.path;
        //
      },
    },
  },
};
</script>
<style lang="scss" scoped>
._nav-bar__head__1uFFd {
  position: fixed;
  width: 100%;
  background: #353f4b;
  z-index: 20;
  border-bottom: 1px solid #e3e3e3;
}
inputs {
  font-size: 12px !important;
}
._nav-bar__logout__3yoqE {
  background: url("/static/image/tuichu.png") no-repeat top center;
  background-size: contain;
  width: 16px !important;
  height: 16px !important;
  display: inline-block;
}

// @import '../../../static/css/chunk-b7675268.a1b3941c.css';
</style>
